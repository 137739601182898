<template>
    <div class="sequencer-container">

        <!-- Create new sequence dialog -->
        <teleport to="body">
            <base-dialog :top="'30'" :width="'30'" :open="show_createsequence" @close="closeCreateSequenceDialog()">
                <h3 style="color: var(--font1)"> Create new sequence </h3>
                <div class="list-input-wrapper wwd-column">
                    <div class="list-input">
                        <it-input class="list-input" :class="{disable: is_disabled}" placeholder="Name of sequence" v-model="new_sequence_name" prefix-icon="list" />
                        <small v-if="is_disabled" style="color: var(--red1)"> Name contains invalid characters  </small>
                    </div>
                    <div class="button-wrapper wwd-row">
                        <it-button :disabled="is_disabled" type="success" class="create-list-button success-button" :loading="creating_sequence" @click="createSequence()">Create</it-button>
                        <it-button type="danger" class="create-list-button danger-button" @click="closeCreateSequenceDialog()" > Cancel </it-button>
                    </div>
                </div>
            </base-dialog>
        </teleport> 
        <!-- End new sequence dialog -->

        <!-- Generate steps dialog -->
        <teleport to="body">
            <base-dialog :top="'20'" :width="'50'" :open="show_generatesteps" @close="closeGenerateStepsDialog()">
                <h3 style="color: var(--font1)"> Generate steps </h3>
                <div class="list-input-wrapper wwd-column">
                    <div class="step-generator-options-wrapper">
                            <div>
                                <label class="generator-label"> Signal </label>
                                <Dropdown class="generate-steps-signal-dropdown sequencer-dropdown" :filter="true" optionLabel="label" :options="signals" v-model="step_generator.signal.label" placeholder="Select signal" />
                            </div>
                        <it-divider class="generator-divider" />
                        <div class="wwd-row list-input-row-wrapper">
                            <div class="wwd-row generate-step-row">
                                <it-icon class="step-generator-icon" name="timer" outlined />
                                <it-divider vertical class="generator-vertical" />
                                <div>
                                    <label class="generator-label"> Start time </label>
                                    <InputNumber @update:modelValue="validateGenerateStep($event, 'start_time')" suffix="s" class="panel-numberinput" :min="0" :step="0.25" showButtons :minFractionDigits="3" v-model="step_generator.start_time" />
                                </div>
                            </div>
                            <div class="wwd-row generate-step-row">
                                <it-icon class="step-generator-icon" name="update" outlined />
                                <it-divider vertical class="generator-vertical" />
                                <div>
                                    <label class="generator-label"> Step interval </label>
                                    <InputNumber @update:modelValue="validateGenerateStep($event, 'time_step')" suffix="s" class="panel-numberinput" :min="0" :step="0.25" showButtons :minFractionDigits="3" v-model="step_generator.time_step" />
                                </div>
                            </div>
                            <div class="wwd-row generate-step-row">
                                <it-icon class="step-generator-icon" name="timer_off" outlined />
                                <it-divider vertical class="generator-vertical" />
                                <div>
                                    <label class="generator-label"> End time </label>
                                    <InputNumber @update:modelValue="validateGenerateStep($event, 'end_time')" suffix="s" class="panel-numberinput" :min="0" :step="0.25" showButtons :minFractionDigits="3" v-model="step_generator.end_time" />
                                </div>
                            </div>
                        </div>
                        <it-divider class="generator-divider" />
                        <div class="wwd-row list-input-row-wrapper">
                            <div class="wwd-row generate-step-row">
                                <it-icon class="step-generator-icon" name="flag" outlined />
                                <it-divider vertical class="generator-vertical" />
                                <div>
                                    <label class="generator-label"> Initial value </label>
                                    <InputNumber suffix="" class="panel-numberinput" :step="0.25" showButtons :minFractionDigits="3" v-model="step_generator.initial_value" />
                                </div>
                            </div>
                            <div class="wwd-row generate-step-row">
                                <it-icon class="step-generator-icon" name="turn_right" outlined />
                                <it-divider vertical class="generator-vertical" />
                                <div>
                                    <label class="generator-label"> Step value </label>
                                    <InputNumber suffix="" class="panel-numberinput" :step="0.25" showButtons :minFractionDigits="3" v-model="step_generator.value_step" />
                                </div>
                            </div>
                        </div>
                        <it-divider class="generator-divider" />
                    </div>
                    <div class="button-wrapper wwd-row">
                        <it-button :disabled="is_disabled" type="success" class="create-steps-button--success success-button" :loading="creating_sequence" @click="generateSteps()">Generate</it-button>
                        <it-button type="danger" class="create-steps-button--danger danger-button" @click="closeGenerateStepsDialog()" > Cancel </it-button>
                    </div>
                </div>
            </base-dialog>
        </teleport> 
        <!-- End generate steps dialog -->

        <div class="sequencer-headrow">
            <span class="headrow-title">
                Sequencer
            </span>
            <span class="headrow-selector">
                <Dropdown 
                class="sequencer-dropdown signal-set-dropdown" 
                :options="signalLists" 
                v-model="$store.state.signal_set" 
                placeholder="Select signal list" 
                @change="updateSignalList()"
                />
                <it-divider vertical class="generator-vertical-title" />
                <Button @click="showCreateSequenceDialog()" style="" label="Create" icon="pi pi-plus" class="action-button p-button-raised p-button-secondary p-button-sm create-sequence-button" />
                <Dropdown @change="updateSequenceData()" class="sequencer-dropdown title-dropdown" emptyMessage="No sequences found, try to create new one" optionLabel="label" :options="sequences_list" v-model="$store.state.chosed_sequence" placeholder="Select sequnce" />
            </span>
        </div>
        <it-divider class="sequencer-divider" />
        <div class="sequencer-panel">
            <div class="sequencer-top-row" v-if="$store.state.chosed_sequence">
                <span class="time-head">
                    Simulation time + t
                </span>
                <span class="action-head">
                    Action
                </span>
                <span class="signal-head">
                    Signal/Subsequence
                </span>
                <span class="value-head">
                    Value
                </span>
            </div>
            <Timeline class="sequencer-timeline" :value="sequencer_events">
                <template #opposite="slotProps">
                    <div class="test">
                        <i class="panel-deleteicon pi pi-trash" v-if="sequencer_events.length > 1" @click="removeEvent(sequencer_events.indexOf(slotProps.item)); onUpdate()"></i>
                        <div>
                            <InputNumber :min="0" @update:modelValue="validateInput($event, sequencer_events.indexOf(slotProps.item)); onUpdate()" @blur="sortActions()" suffix="s" class="panel-numberinput" :step="0.25" showButtons :minFractionDigits="2" :maxFractionDigits="2" v-model="sequencer_events[sequencer_events.indexOf(slotProps.item)].time" />
                        </div>
                        <small class="p-text-secondary">Step #{{sequencer_events.indexOf(slotProps.item)}}</small>
                    </div>
                    <Button :disabled="!$store.state.chosed_sequence" @click="addNewEvent(slotProps.item.time);onUpdate()" icon="pi pi-plus"  class="between-step p-button-sm p-button-secondary p-button-text" />
                    <Button :disabled="!$store.state.chosed_sequence" @click="addComment(slotProps.item.time);onUpdate()" icon="pi pi-comment"  class="between-step p-button-sm p-button-secondary p-button-text" />

                </template>
                <template #content="slotProps">
                        <div class="sequencer-icon-wrapper">
                            <img v-if="slotProps.item.status1 === 'Manage breaker'" class="sequencer-breaker-icon" src="@/assets/breaker_open.png">
                            <it-icon v-else-if="slotProps.item.status1 === 'Set digital input'" class="sequencer-device-icon" name="radio_button_checked" outlined />
                            <it-icon v-else-if="slotProps.item.status1 === 'Set value'" class="sequencer-tune-icon" name="tune" outlined />
                            <it-icon v-else-if="slotProps.item.status1 === 'Run subsequence'" class="sequencer-subsequence-icon" name="account_tree" outlined />
                            <it-icon v-else-if="slotProps.item.status1 === 'comment'" class="sequencer-commentline-icon" name="comment" outlined />
                            
                        </div>
                        
                        <div class="sequencer-actions-wrapper">
                                <div v-if="slotProps.item.status1 === 'comment'" class="additional-comment-wrapper">
                                    <InputText @input="onUpdate()" class="additional-comment-input" v-model="slotProps.item.value" type="text" placeholder="Comment line" />
                                </div>

                                <div v-else class="sequencer-actions-wrapper">
                                    <Dropdown class="sequencer-dropdown panel-dropdown" @change="clearAction(sequencer_events.indexOf(slotProps.item)); onUpdate()" :options="choose_options_primary" v-model="sequencer_events[sequencer_events.indexOf(slotProps.item)].status1" placeholder="Select action" />
    
                                    <Dropdown v-if="slotProps.item.status1" @change="onUpdate()" class="sequencer-dropdown panel-dropdown" :filter="true" optionLabel="label" :options="generateSecondOptions(sequencer_events[sequencer_events.indexOf(slotProps.item)].status1)" v-model="sequencer_events[sequencer_events.indexOf(slotProps.item)].status2" placeholder="Select action">
                                        <template #value="dropSlotProps">
                                            <div v-if="dropSlotProps.value?.label" class="dropdown-value" :class="{'signal-warning': !!!this.signals?.find(signal => signal.label === dropSlotProps.value?.label) && slotProps.item.status1 !== 'Run subsequence'}">
                                                {{ dropSlotProps.value?.label }}
                                                <!-- {{ generateSecondOptions(sequencer_events[sequencer_events.indexOf(slotProps.item)].status1) }} -->
                                            </div>
                                            <span v-else>
                                                <div class="option-placeholder"> {{ slotProps.item.status1 === "Run subsequence" ? "Select subsequence" : "Select signal" }} </div>
                                            </span>
                                        </template>              
                                
                                    </Dropdown>    
    
                                    <InputNumber v-if="slotProps.item.status1 === 'Set value'" @input="onUpdate()" placeholder="Value" class="sequencer-input" id="minmax-buttons" v-model="sequencer_events[sequencer_events.indexOf(slotProps.item)].status3" mode="decimal" showButtons />
                                    <Dropdown v-else-if="['Manage breaker', 'Set digital input'].includes(slotProps.item.status1)" @change="onUpdate()" optionLabel="name" optionValue="value" class="sequencer-dropdown panel-dropdown" :options="generateThirdOptions(sequencer_events[sequencer_events.indexOf(slotProps.item)].status1)" v-model="sequencer_events[sequencer_events.indexOf(slotProps.item)].status3" placeholder="Select action"> 
                                    </Dropdown> 
                                    
                                    <div class="subsequence-time" v-if="slotProps.item.status1 === 'Run subsequence' && slotProps.item.status2?.label">
                                        <div v-if="this.$store.getters.sequencesList[slotProps.item.status2.label]">
                                            <div v-if="!this.$store.getters.sequencesList[slotProps.item.status2.label].some(action => action.status1 === 'Run subsequence')">
                                                → Subsequence will finish in {{ this.$store.getters.sequencesList[slotProps.item.status2.label].at(-1).time + slotProps.item.time }}s
                                            </div>
                                            <div v-else>
                                                → Can't caluclate time (nested subsequence)
                                            </div>
                                            
                                        </div>
                                        <div class="no-subsequence" v-else>
                                            → Subsequence doesn't exist!
                                        </div>
                                    </div>
                                    
                                </div>
                            <!-- <div v-if="!['Run subsequence', 'comment'].includes(slotProps.item.status1) && slotProps.item.status1" class="sequencer-comments">
                                <it-icon class="sequencer-comment-icon" name="comment" outlined />
                                <InputText v-model="sequencer_events[sequencer_events.indexOf(slotProps.item)].comment" class="sequencer-comment-input" type="text" placeholder="Additional comment" />
                            </div> -->
                            
                        </div>
                </template>
            </Timeline>
        </div>
        <div class="footer-wrapper">
            <it-divider class="sequencer-divider" />
            <div class="sequencer-footer">
                <div class="footer-left">
                    <Button :disabled="!$store.state.chosed_sequence" :loading="deleting_sequence" label="Delete" icon="pi pi-trash" class="action-button p-button-raised p-button-danger p-button-sm" @click="deleteSequence()" />
                    <Button :disabled="!$store.state.chosed_sequence" @click="showGenerateStepsDialog()" icon="pi pi-sort-numeric-down" label="Generate ramp" class="action-button p-button-secondary p-button-sm" /> 
                </div>
                <div class="footer-right">
                    <Button :disabled="!$store.state.chosed_sequence" :loading="saving_sequence" label="Save" icon="pi pi-save" class="action-button p-button-raised p-button-info p-button-sm" @click="saveSequence()" />
                    <Button :disabled="!connectedToSimulation || !$store.state.chosed_sequence" @click="runSequence()" label="Run" icon="pi pi-play" class="action-button p-button-raised p-button-success p-button-sm" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown';
    import Timeline from 'primevue/timeline';
    import InputNumber from 'primevue/inputnumber';
    import BaseDialog from '@/components/ui_items/BaseDialog.vue';
    import InputText from 'primevue/inputtext';
    export default {
        components: {
            Button,
            Dropdown,
            Timeline,
            InputNumber,
            BaseDialog,
            InputText
        },
        data() {
            return {
                sequences_list: [],
                sequencer_events: [],
                value: null,
                choose_options_primary: ['Set value', 'Manage breaker', 'Set digital input', 'Run subsequence'],
                signals: [],
                show_createsequence: false,
                show_generatesteps: false,
                new_sequence_name: null,
                is_disabled: false,
                creating_sequence: false,
                step_generator: {
                    signal: {
                        label: null
                    },
                    start_time: null,
                    end_time: null,
                    time_step: null,
                    initial_value: null,
                    value_step: null
                },
                saving_sequence: false,
                deleting_sequence: false
            }
        },
        computed: {
            //reference to user signals list's
            signalLists() {
                return Object.keys(this.$store.getters.signalSets)
            },
            //reference to choosed signal list
            signalListComputed(){
                return this.$store.state.signal_set ? this.$store.getters.signalSets[this.$store.state.signal_set].split('\n') : null
            },
            connectedToSimulation() {
                return this.$store.getters.connectionStatus == 2
            },
        },
        methods: {
            //create new blank event
            addNewEvent(time) {
                console.log(time)
                this.sequencer_events.push({
                    status1: null, status2: { label: null }, status3: null, time: time
                })
                this.sortActions()

            },
            addComment(time) {
                this.sequencer_events.push({
                    status1: 'comment',
                    time: time,
                    value: null
                })
                this.sortActions()

            },
            //delete event by index
            removeEvent(event) {
                this.sequencer_events.splice(event, 1)
            },
            //return signal list or sequences lists depending on status1
            generateSecondOptions(event) {
                switch (event) {
                    case 'Run subsequence':
                        return this.sequences_list
                    case 'Manage breaker':
                        return this.signals.filter(signal => signal.label.toLowerCase().includes('breaker') && !signal.label.toLowerCase().includes('status'))
                    case 'Set digital input':
                        return this.signals.filter(signal => signal.label.toLowerCase().includes('di'))
                    case 'Set value':
                        return this.signals.filter(signal => !signal.label.toLowerCase().includes('di') && !signal.label.toLowerCase().includes('breaker'))
                    default:
                        return this.signals;
                }
            },
            //return dropdown options depending on status1 - ((note: for option set value HTML is handling line54 v-if statemtnt))
            generateThirdOptions(event) {
                switch (event) {
                    case 'Manage breaker':
                        return [{name:'Open breaker', value: 1}, {name:'Close breaker', value: 2}, {name:'Force open', value: 3}, {name:'Force close', value: 4}]
                    case 'Set digital input':
                        return [{name: 'True', value: 1}, {name: 'False', value: 0}]
                }
            },
            //get the signals from selected signal list and modify them as a object so dropdown can have filter service
            updateSignalList() {
                this.signals = []
                if (this.$store.state.signal_set) {
                    for (const signal of this.$store.getters.signalSets[this.$store.state.signal_set].split('\n')) {
                        this.signals.push({label: signal})
                    }
                }
                this.signals = this.signals.filter(signal => signal.label.toLowerCase().includes('_cmd') || signal.label.toLowerCase().includes('di') || signal.label.toLowerCase().includes('pv') )
            },
            //start sequence alghoritm
            async runSequence() {
                this.$store.state.stop_getting = false
                try {
                    this.$store.dispatch('runSequence', {data: this.sequencer_events, name: this.$store.state.chosed_sequence})
                    this.$emit('closeDialog')
                    this.$store.commit('setSidebar', {visible: true})
                } catch(err) {
                    this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 2000}); 
                }
            },
            //get the list of sequences and modify them as a object so dropdown can have filter service
            async updateListOfSequences() {
                let sequences_modified = []
                await this.$store.dispatch('getListOfSequences')
                for (const sequence of this.$store.getters.sequencesListKeys) {
                        sequences_modified.push({label: sequence})
                    }
                this.sequences_list = sequences_modified
            },
            //after changing sequence change display data for sequencer
            updateSequenceData() {
                const matching_list =  Object.keys(this.$store.getters.signalSets).find(list => this.$store.getters.scenarioTags.some(tag => list.includes(tag)))
                if (matching_list) {
                    this.$store.state.signal_set = matching_list
                    this.updateSignalList()
                }
                if (this.$store.state.chosed_sequence) {
                    this.sequencer_events = this.$store.getters.sequencesList[this.$store.state.chosed_sequence.label]
                }
            },
            //save sequence data to database
            async saveSequence() {
                this.saving_sequence = true
                try {
                    await this.$store.dispatch('saveSequence', {
                        sequence_name: this.$store.state.chosed_sequence.label,
                        data: this.sequencer_events
                    })
                    await this.updateListOfSequences()
                    this.$toast.add({severity:'success', summary: 'Success!', detail: `Sequence saved.`, life: 2000}); 
                    this.saving_sequence = false
                    this.$store.commit('setSaved', true)
                }
                catch(err) {
                    console.log(err)
                    this.$toast.add({severity:'error', summary: 'Error', detail: `Failed to save sequence`, life: 2000}); 
                    this.saving_sequence = false
                }
            },
            //create new blank sequence 
            async createSequence() {
                this.creating_sequence = true
                await this.$store.dispatch('createSequence', {
                    data: [
                    {
                        "status1": "",
                        "status2": {
                            "label": ""
                        },
                        "status3": 0,
                        "time": 0,
                        "comment": null
                    }],
                    sequence_name: this.new_sequence_name
                })
                this.creating_sequence = false
                await this.updateListOfSequences()
                this.$store.state.chosed_sequence = {label: this.new_sequence_name}
                this.updateSequenceData()
                this.new_sequence_name = null
                this.closeCreateSequenceDialog()
            },
            showCreateSequenceDialog() {
                this.show_createsequence = true
            },
            closeCreateSequenceDialog() {
                this.show_createsequence = false
            },
            showGenerateStepsDialog() {
                this.show_generatesteps = true
            },
            closeGenerateStepsDialog() {
                this.show_generatesteps = false
            },
            //delete sequence and set active sequence to 1st sequence on the list
            async deleteSequence() {
                this.deleting_sequence = true
                try {
                    await this.$store.dispatch('deleteSequence', {
                    sequence_name: this.$store.state.chosed_sequence.label
                })
                    this.$toast.add({severity:'success', summary: 'Success!', detail: `Sequence deleted.`, life: 2000}); 
                    this.deleting_sequence = false
                    this.$store.state.chosed_sequence = null
                    this.sequencer_events = []
                    await this.updateListOfSequences()
                    this.deleting_sequence = false
                }
                catch(err) {
                    console.log(err)
                    this.$toast.add({severity:'error', summary: 'Error', detail: `Failed to delete sequence`, life: 2000}); 
                    this.deleting_sequence = false
                }
            },
            clearAction(index) {
                if (this.sequencer_events[index]) {
                    this.sequencer_events[index].status2 = {label: null}
                    this.sequencer_events[index].status3 = 0,
                    this.sequencer_events[index].comment = null
                }
            },
            generateSteps() {
                let steps = []
                let initial_value = this.step_generator.initial_value
                for (let start_time = this.step_generator.start_time; start_time < this.step_generator.end_time + this.step_generator.time_step; start_time += this.step_generator.time_step) {
                    steps.push({
                        status1: 'Set value',
                        status2: this.step_generator.signal.label,
                        status3: initial_value,
                        time: start_time
                    })
                    initial_value += this.step_generator.value_step
                }
                this.sequencer_events = [...this.sequencer_events, ...steps]
                this.sortActions()
                this.closeGenerateStepsDialog()
            },
            validateInput(event, index) {
                const round_values = [0, 0.25, 0.50, 0.75]
                const decimals = event - Math.floor(event)
                const closest_decimal = round_values.map(x => Math.abs(x - decimals))
                const next_value = round_values[closest_decimal.indexOf(Math.min(...closest_decimal))]
                if (!round_values.includes(decimals)) {
                    this.$nextTick(() => {
                        this.sequencer_events[index].time = Math.floor(event) + next_value
                    })
                }
            },
            validateGenerateStep(event, item) {
                const round_values = [0, 0.25, 0.50, 0.75]
                const decimals = event - Math.floor(event)
                const closest_decimal = round_values.map(x => Math.abs(x - decimals))
                const next_value = round_values[closest_decimal.indexOf(Math.min(...closest_decimal))]
                if (!round_values.includes(decimals)) {
                    this.$nextTick(() => {
                        this.step_generator[item] = Math.floor(event) + next_value
                    })
                } 
            },
            sortActions() {
                setTimeout(() => {
                    this.$nextTick(() => {
                        this.sequencer_events.sort((a) => a.status1 === 'comment' ? -1 : 1 )
                        this.sequencer_events.sort((a,b) => {
                                return a.time < b.time ? -1 : 1
                            })
                    })
                }, 10);
            },
            onUpdate() {
                this.$store.commit('setSaved', false)
            }
        },
        watch: {
            //if user change sequence -> update sequence data
            // chosed_sequence() {
            //     this.updateSequenceData() 
            // },
            //check if new sequence name is correct
            new_sequence_name(newValue) {
                if (newValue?.includes('.') || newValue?.includes(',') || newValue?.includes('/')) {
                    this.is_disabled = true
                } else {
                    this.is_disabled = false
                }
            }
        },
        //get list of sequences and list of signals
        created() {
            this.updateListOfSequences()
            this.updateSignalList()
        },
        mounted() {
            this.updateSequenceData()
        }
    }
</script>

<style scoped>
.sequencer-container {
    display: flex;
    flex-direction: column;
    color: var(--font1);
    padding: 0.5rem 0.1rem;
    overflow: auto;
    width: 100%;
}
.sequencer-headrow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: fit-content;
    margin-right: 2rem;
    align-items: center;
    flex-wrap: wrap;
}
.headrow-title {
    font-size: 1.5rem;
    font-family: var(--font_poppins);
}
.headrow-selector {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
}
.sequencer-panel {
    min-height: 68vh;
    max-height: 68vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
}
.create-event-button {
    margin: 1rem 1rem 0 0;
    width: 8rem;
    min-height: 2rem;
}
.nextstep-wrapper {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
}
.sequencer-footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.footer-left > * {
    margin: 0 0.25rem;
}
.sequencer-dropdown {
    width: 10rem;
    padding: 0.1rem;
    box-shadow: unset !important;
}
::v-deep(.p-dropdown) {
    background: var(--color1) !important;
}
.sequencer-dropdown:deep(.p-dropdown-trigger) {
    background-color: var(--color3) !important;
    color: var(--font1) !important;
    border-radius: 0 !important;
    background-color: var(--color3) !important;
    border: unset !important;
}
.sequencer-dropdown:hover {
    border-color: var(--color7) !important;
}
.sequencer-dropdown:deep(.p-inputtext) {
    border-radius: 0 !important;
    background-color: var(--color2);
    border: unset !important;
    /* color: var(--font1) !important; */
}
.sequencer-dropdown:not(.p-disabled).p-focus {
    border-color: var(--color7);
    box-shadow: unset !important;
}
.sequencer-dropdown:deep(li.p-dropdown-item) {
    background-color: var(--color3) !important;
}
.sequencer-dropdown:deep(.p-dropdown-items .p-dropdown-item) {
    background-color: red !important;
}
.sequencer-dropdown:deep(.p-dropdown .p-dropdown-label.p-placeholder) {
    color: #6c757d !important;
}
.p-dropdown .p-dropdown-label {
    border: unset !important;
}
.panel-dropdown {
    /* height: 1.9rem !important; */
    width: 20rem !important;
    margin-right: 0.4rem;
}
.panel-dropdown:deep(.p-inputtext) {
    display: flex;
    align-items: center;
}
.sequencer-input {
    min-width: 20rem;
}
.test {
    display: flex;
    justify-content: space-between;
}
.panel-deleteicon {
    margin: 0.5rem 0.5rem 0 0;
}

.panel-deleteicon:hover {
    cursor: pointer;
    color: var(--woodward_red);
    transition: color 0.1s;
}

.panel-numberinput:deep(.p-inputnumber-input) {
    width: 5rem !important;
    height: 2rem;
    border-top-left-radius: 10%;
    border-bottom-left-radius: 10%;
    color: var(--font1);
    /* border-radius: 10%; */
}
.panel-numberinput:deep(.p-button.p-button-icon-only) {
    width: 1rem;
    height: 1rem;
}
.list-input {
    width: 70%;
}
.button-wrapper {
    margin-top: 10px;
    width: 100%;
    gap: 0.5rem;
}
.create-list-button {
    margin-right: 5px;
}
.success-button {
    background-color: rgb(55, 143, 14);
    border-color: rgb(55, 143, 14);
    box-shadow: none;
}
.danger-button {
    background-color: rgb(196, 13, 0);
    border-color: rgb(196, 13, 0);
    box-shadow: none;
}
.action-button {
    margin: 0 0.2rem;
    height: 2rem !important;
}
.create-list-button {
    width: 10rem;
}
.dialog-header {
    color: var(--font1);
}
.dialog-body {
    color: var(--font1);
}
.disable {
    border-color: red !important;
}
.sequencer-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 4.5rem;
    min-height: 3rem;
}
.sequencer-breaker-icon {
    width: 50px;
    margin-right: 1rem;
}
.sequencer-tune-icon {
    font-size: 25px;
    margin-right: 1rem;
    color: var(--green2);
}
.sequencer-device-icon {
    font-size: 25px;
    margin-right: 1rem;
    color: rgb(255, 61, 61);
}
.sequencer-commentline-icon {
    font-size: 25px;
    margin-right: 1rem;
    color: var(--font1);
}
.sequencer-subsequence-icon {
    font-size: 25px;
    margin-right: 1rem;
    color: var(--blue1);
}
.sequencer-condition-icon {
    font-size: 25px;
    margin-right: 1rem;
    color: var(--grey);
}
.sequencer-comment-icon {
    font-size: 20px;
    margin-right: 1rem;
    color: var(--blue1)
}
.sequencer-comment-input {
    color: var(--blue1) !important;
}
.sequencer-actions-wrapper {
    display: flex;
    /* justify-content: space-between; */
    width: 100% !important;
    align-items: center;
}
.step-generator-options-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
}
.step-generator-options-wrapper > * {
    width: 10rem;
}
.generator-label {
    color: var(--font1);
    margin-bottom: 5px;
}
.list-input-row-wrapper {
    gap: 1.5rem;
}
.generator-divider {
    width: 50% !important;
    min-width: 50% !important;
    background-color: var(--color3); 
}
.step-generator-icon {
    font-size: 1.5rem;
    color: var(--silver);
}
.generator-vertical {
    height: 3rem !important;
    margin-top: 0.2rem;
    background-color: rgb(81, 79, 79) !important;
}
.generator-vertical-title {
    height: 2rem !important;
    margin-top: 0.2rem;
    background-color: rgb(81, 79, 79) !important;
}
.create-steps-button--success {
    min-width: 6rem !important;
    background-color: var(--blue1);
    border-color: var(--blue1);
}
.create-steps-button--danger {
    min-width: 6rem !important;
    background-color: var(--red2);
    border-color: var(--red2);
}
.title-signals {
    width: 15rem;
}
.title-dropdown {
    width: 15rem !important;

}
.signal-set-dropdown {
    width: 21rem !important;

}
.title-dropdown:deep(.p-dropdown-label) {
    display: flex;
    justify-content: center;
    align-items: center;
}
.create-sequence-button {
    height: 2.1rem !important;
}
.sequencer-divider {
    background-color: grey;
}
.generate-step-row {
    display: flex;
    justify-content: center;
    align-items: center;
}
.generate-steps-signal-dropdown {
    width: 20rem !important;
}
.option-placeholder {
    color: #636b72 !important;
}
.additional-comment-wrapper {
    display: flex;
    width: 100%;
}
.additional-comment-input {
    width: 100%;
    color: var(--orange) !important;
    background-color: unset !important;
}
.sequencer-top-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.6rem;
    color: var(--font1);
    font-weight: 600;

}
.time-head {
    min-width: 19.5rem;
}
.action-head {
    min-width: 20.5rem;
}
.signal-head {
    min-width: 20.5rem;
}
.dropdown-value {
    color: var(--font1);
}
.subsequence-time {
    color: var(--blue1)
}
.signal-warning {
    position: relative;
}
.signal-warning::before {
    position: absolute;
    content: 'Signal doesn\'t exsist in selected signal list!';
    top: 16px;
    font-size: 0.71rem;
    font-weight: 700;
    color: var(--red1)
}
.no-subsequence {
    color: var(--red1)
}
.between-step {
    color: var(--font1)
}


</style>