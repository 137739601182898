<template>
    <div>
        <ConfirmPopup></ConfirmPopup>
        <div class="backdrop" v-if="open"></div>
        <transition name="modal">
            <dialog v-if="open" open>
                <div class="close-button">
                    <Button @click="confirm($event)" class="close-button p-button-secondary p-button-text close-buttton" icon="pi pi-times"></Button>
                </div>
                <slot></slot>
            </dialog>
        </transition>
    </div>            
</template>

<script>
import Button from 'primevue/button'
import ConfirmPopup from 'primevue/confirmpopup';
export default {
    props: ['open','top','width', 'height'],
    components: {
        Button,
        ConfirmPopup
    },
    methods: {
        confirm(event)  {
            if (!this.$store.getters.isSaved) {
                this.$confirm.require({
                    target: event.currentTarget,
                    message: 'Unsaved data will be lost, are you sure you want to proceed?',
                    icon: 'pi pi-exclamation-triangle',
                    rejectClass: 'p-button-secondary p-button-outlined p-button-sm',
                    acceptClass: 'p-button-sm',
                    rejectLabel: 'Cancel',
                    acceptLabel: 'Close',
                    accept: () => {
                        this.$emit('close')
                        this.$store.commit('setSaved', true)
                        return
                    },
                    reject: () => {
                        return
                    }
                });
            } else {
                this.$emit('close')
            }

}}}
</script>

<style scoped>
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 60;
    background-color: rgba(0, 0, 0, 0.75);
}
.close-button {
    position: absolute;
    right: 5px;
    top: 2px;
    color: var(--color3);
}
dialog {
    position: absolute;
    top: 40px;
    width: 10%;
    margin: 0 auto;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    border-radius: 6px;
    padding: 1rem;
    width: 90%;
    min-width: 35rem;
    background-color: var(--color2);
    z-index: 60;
    border: none;
    overflow: auto;
}

dialog::-webkit-scrollbar-thumb {
    border-radius: 10px;
}
.modal-enter-active {
    animation: modal-in 0.4s ease-out;
}
.modal-leave-active {
    animation: modal-out 0.4s ease-in;
}

@keyframes modal-in {
    from {
        opacity: 0;
        transform: scale(0.8)
    }
    to {
        opacity: 1;
        transform: scale(1)
    }
}
@keyframes modal-out {
    from {
        opacity: 1;
        transform: scale(1)
    }
    to {
        opacity: 0;
        transform: scale(0.8)
    }
}
.close-button {
    color: var(--font1);
}

</style>